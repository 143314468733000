body {
  height: 100%;
  background: #7b8187 !important;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  justify-content: center;
}